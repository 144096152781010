import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AuthGuard } from './core/guards/auth.guard'
import { ErrorPageComponent } from '@ct-fwh/ui-component'
import { environment } from 'src/environments/environment'

const featureList: Record<string, boolean> = environment.featureList

const appRoutes: Routes = []
let defaultRedirect = 'not-found'

if (featureList.login) {
    appRoutes.push({
        path: 'login',
        loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule),
    })
    defaultRedirect = 'login'
}

if (featureList.app) {
    appRoutes.push({
        path: '',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
        canActivate: [AuthGuard],
    })
    defaultRedirect = 'home'
}

const routes: Routes = [
    {
        path: '',
        redirectTo: defaultRedirect,
        pathMatch: 'full',
    },
    ...appRoutes,
    {
        path: 'not-found',
        component: ErrorPageComponent,
    },
    {
        path: '**',
        redirectTo: 'not-found',
    },
]

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            // initialNavigation: 'enabled',
        }),
    ],
    exports: [RouterModule],
    providers: [AuthGuard]
})
export class AppRoutingModule {}
