import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  AuthenticatedUserResponse,
  AuthenticateUserRequest,
  AuthenticationStoreDataResponse,
  LogoutUserRequest,
  LogoutUserResponse,
} from '@ct-ibd/cmn-ibd-typescript-sdk';
import { BehaviorSubject, Observable } from 'rxjs';
import { _CmnSdkWrapperService } from '../cmn-sdk-wrapper/cmn-sdk-wrapper.service';

@Injectable({ providedIn: 'root' })
export class AuthService {
  private logged$: BehaviorSubject<boolean | null> = new BehaviorSubject<
    boolean | null
  >(null);

  constructor(private sdk: _CmnSdkWrapperService, private router: Router) {}

  get logged(): Observable<boolean | null> {
    if (this.logged$.value === null) {
      this.init();
    }
    return this.logged$;
  }

  private loggedIn() {
    this.logged$.next(true);
  }

  private loggedOut() {
    // if logout request was unsuccessful we need to clear it ourselves
    this.sdk.authenticationManager.clearStore();
    const wasLogged = this.logged$.value;
    this.logged$.next(false);
    // only navigate if user was previously logged in
    if (wasLogged) {
      this.router.navigateByUrl('/login');
    }
  }

  private init() {
    this.loginCheck();
  }

  // istanbul ignore next
  public async authenticate(
    request: AuthenticateUserRequest
  ): Promise<AuthenticatedUserResponse | null> {
    // remove token for all login API call's
    this.sdk.authenticationManager.removeAuth();
    return this.sdk.authenticationManager
      .authenticateUser(request)
      .then((result) => {
        if (result !== null) {
          // add token after login API call's
          this.sdk.authenticationManager.addAuth();
          this.loginCheck();
        }
        return result;
      });
  }

  public async loginCheck(): Promise<{
    logged: boolean;
    tokenChanged: boolean;
  }> {
    const token =
      this.sdk.authenticationManager.getAuthenticationStoreData().accessToken;
    return this.sdk.authenticationManager
      .loginCheck()
      .then((x) => {
        if (x !== null && x.accessToken !== null) {
          this.loggedIn();
          return { logged: true, tokenChanged: token !== x.accessToken };
        } else {
          this.loggedOut();
          return { logged: false, tokenChanged: token !== null };
        }
      })
      .catch(() => {
        // istanbul ignore next
        this.loggedOut();
        return { logged: false, tokenChanged: true };
      });
  }

  public logout(): void {
    this.logoutRequest(new LogoutUserRequest());
    this.loggedOut();
  }

  private async logoutRequest(
    request: LogoutUserRequest
  ): Promise<LogoutUserResponse | null> {
    return this.sdk.authenticationManager
      .logoutUser(request)
      .then((response) => response)
      .catch((error) => error);
  }

  public getStoreData() {
    return this.sdk.authenticationManager.getAuthenticationStoreData();
  }

  // istanbul ignore next
  public userUpdate(update: Partial<AuthenticationStoreDataResponse>) {
    Object.entries(update).forEach(([key, value]) => {
      this.sdk.authenticationManager.store.add(key, value);
    });
  }

  refreshToken() {
    return this.sdk.authenticationManager.refreshAuthentication().catch(() => {
      this.logout();
    });
  }
}
