import { Injectable } from '@angular/core'
import { Store } from '../../interfaces/store.interface'

// no reason to test it. Super simple mappings to have a service
// istanbul ignore next
@Injectable({
    providedIn: 'root',
})
export class StorageService implements Store {
    private localStore = localStorage

    add(key: string, value: string): boolean {
        this.localStore.setItem(key, value)
        return true
    }

    get(key: string): string | null {
        return this.localStore.getItem(key) ?? null
    }

    del(key: string): boolean {
        this.localStore.removeItem(key)
        return true
    }

    isValid(key: string): boolean {
        return this.localStore.getItem(key) ? true : false
    }
    setCollection(name: string): void {
        // This function does nothing. added comment to skip sonarCube "Unexpected empty method" error
    }
}
